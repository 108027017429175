const colors = {
  transparentColor: {
    primary: 'transparent',
  },
  transparency: {
    button: 20,
    controller: 70,
    input: {
      primary: '19',
      secondary: '15',
    },
    typoghraphy: 80,
  },
  // black: '#1f1d1d',
  blue: '#070E40',
  white: '#ffffff',
  darkPaper: '#2b2728',
  primary: {
    // dark: '#A4c627',
    dark: '#a4c627',
    light: '#101944',
  },
  secondary: {
    // dark: '#546A00',
    dark: '#546A00',
    light: '#3348A7',
  },
  error: {
    // dark: '#F44236',
    dark: '#F44236',
    light: '#D50D00',
  },
  warning: {
    // dark: '#FF9900',
    dark: '#FF9900',
    light: '#FF9300',
  },
  info: {
    // dark: '#90C9F9',
    dark: '#90c9f9',
    light: '#37A3FD',
  },
  success: {
    dark: '#A4C627',
    light: '#A4C627',
  },
  selectedBG: {
    dark: '#A4c62720',
    light: '#C7CBDB',
  },
  text: {
    primary: {
      // dark: '#EDEDED',
      dark: '#FFFFFF',
      light: '#3D3A41',
    },
    secondary: {
      // dark: '#8A8A8E',
      // dark: '#B5b5b5',
      dark: '#ffffff',
      light: '#8A8A8E',
    },
    disabled: {
      // dark: '#3D3A41',
      dark: '#4D4D4D',
      light: '#C6C6C6',
    },
  },
};

module.exports = colors;
